import React, { useMemo, useState, useEffect } from 'react';
import useFetch from "../../../hooks/useFetch";
import { getImageUrl } from "../../../services/util";

const Services = ({ apiId }) => {
  const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=ServicesComponent&populate[1]=ServicesComponent.icon`;
  const { response } = useFetch(url);

  const [services, setServices] = useState([]);

  const memoizedServices = useMemo(() => {
    return createResponse(response);
  }, [response]);

  useEffect(() => {
    if (memoizedServices) {
      setServices(memoizedServices);
    }
  }, [memoizedServices]);

  return (
    <div className="row w-100 h-100">
      {services.map((item) => (
        <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-center" key={item.id}>
          <div className="service-icon-container text-center">
            {item.icon.map((icon) => (
              <img key={icon.id} src={getImageUrl(icon.url)} alt={icon.alt} loading="lazy" className="img-fluid " />
            ))}
          </div>
          <div className="service-header-container">
            <h4>{item.heading}</h4>
          </div>
          <div className="service-description-container">
            {item.description}
          </div>
        </div>
      ))}
    </div>
  );
};

function createResponse(response) {
  const services = [];

  if (response && response.data !== undefined) {
    const data = response.data.attributes || {};
    if (data.ServicesComponent) {
      data.ServicesComponent.forEach((service) => {
        const serviceIcon = service.icon ? service.icon.data.attributes : null;
        const icon = serviceIcon ? [{
          id: serviceIcon.id,
          url: serviceIcon.url,
          alt: serviceIcon.alternativeText,
        }] : [];

        services.push({
          id: service.id,
          heading: service.heading,
          description: service.description,
          icon: icon,
        });
      });
    }
  }

  return services;
}

export default Services;
