import useFetch from "../../hooks/useFetch";

function CompanyInformationComponent({apiId}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "/?populate[0]=ContactInformation";
    const {response} = useFetch(url);

    const companyInformation = [];
    createResponse(response);

    return (
        companyInformation ? companyInformation.map((item) => (
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 position-relative" key={item.id}>
                <div className="separator"></div>

                <div className="information-container py-sm-2 py-md-2">
                    <p>{item.title}</p>
                    {
                        item.phone ? <p>T: {item.phone}</p> : ""
                    }
                    {
                        item.email ? <p>E: {item.email}</p> : ""
                    }
                </div>
            </div>
        )) : ""
    )

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data && data.ContactInformation) {
                const companyContactInformation = data.ContactInformation;
                companyContactInformation.forEach(function (information) {
                    companyInformation.push({
                        id: information.id,
                        title: information.title,
                        phone: information.phone,
                        email: information.email,
                    })
                })
            }
        }
    }
}

export default CompanyInformationComponent;