import useFetch from "../../../hooks/useFetch";
import PropTypes from "prop-types";
import { getImageUrl } from "../../../services/util";

function Information({apiId, slug}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?filters[category][slug][$eq]=" + slug + "&populate[0]=Information&populate[1]=Information.Image";

    const {response} = useFetch(url);

    let productInformation = null;
    createResponse(response);

    return (
        productInformation ?
            <div className="row content-middle" key={productInformation.id}>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="information-product-name">
                        <h5>
                            {productInformation.product_name}
                        </h5>
                    </div>

                    <div className="information-heading">
                        <h2>
                            {productInformation.heading}
                        </h2>
                    </div>

                    <div className="information-description">
                        {productInformation.description}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="product-image-container text-center">
                        <img loading="lazy" alt={productInformation.alt}
                             src={getImageUrl(productInformation.image)}
                             className="img-fluid product-image"/>
                    </div>
                </div>
            </div> : null
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];

            if (data) {
                data.forEach(product => {
                    const information = product.attributes ? product.attributes.Information : [];
                    const image = information.Image ? information.Image.data.attributes : [];
                    productInformation = {
                        id: information.id,
                        product_name: information.ProductName,
                        heading: information.Heading,
                        description: information.Description,
                        image: image.url,
                        alt: image.alternativeText,
                    }
                })
            }
        }
    }
}

Information.propTypes = {
    apiId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};

export default Information;