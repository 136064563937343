import useFetch from "../../../hooks/useFetch";
import PropTypes from "prop-types";
import { getImageUrl } from "../../../services/util";

function Benefits({apiId, slug}) {
    const url = process.env.REACT_APP_API_PATH + apiId + "?filters[category][slug][$eq]=" + slug + "&populate[0]=Benefits.icon";
    const {response} = useFetch(url);

    const benefits = [];
    createResponse(response);

    return (
        <div>
            <div className="information-product-name">
                <h5>
                    Benefits
                </h5>
            </div>

            <div className="row">
                {
                    benefits ? benefits.map((item) => (
                        <div className="col-lg col-md col-sm-12 col-12 d-inline-grid" key={item.id}>
                            <div className="benefits-main-container">
                                <div className="service-icon-container">
                                    {
                                        item.image !== null ? (<img key={item.id} src={getImageUrl(item.image)}
                                                                    alt={item.alt}
                                                                    loading="lazy" className="img-fluid"/>) : ''
                                    }
                                </div>

                                <div className="service-header-container">
                                <h4>
                                        {item.heading}
                                    </h4>
                                </div>

                                <div className="service-description-container">
                                    {item.description}
                                </div>
                            </div>
                        </div>
                    )) : null
                }
            </div>
        </div>
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];
            if (data) {
                data.forEach(product => {
                    const productBenefits = product.attributes ? product.attributes.Benefits : [];
                    productBenefits.forEach(benefit => {
                        const benefitIcon = benefit.icon?.data?.attributes || {};
                        benefits.push({
                            id: benefit.id,
                            heading: benefit.heading,
                            description: benefit.description,
                            image: benefitIcon.url || null,
                            alt: benefitIcon.alternativeText || null,
                        })
                    })
                })
            }
        }
    }
}

Benefits.propTypes = {
    apiId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};

export default Benefits;