import PropTypes from "prop-types";
import useFetch from "../../../hooks/useFetch";
import {getImageUrl} from "../../../services/util";
import {useState} from "react";

function Features({apiId, slug, isRelatedServices}) {
    let url = null;
    if (isRelatedServices) {
        url = process.env.REACT_APP_API_PATH + apiId + "?populate[0]=Feature.image";
    } else {
        url = process.env.REACT_APP_API_PATH + apiId + "?filters[category][slug][$eq]=" + slug + "&populate[0]=Feature.image";
    }

    const {response} = useFetch(url);

    const [expandedFeatureId, setExpandedFeatureId] = useState(null);

    const features = [];
    createResponse(response, isRelatedServices);

    const toggleDescription = (id) => {
        setExpandedFeatureId(expandedFeatureId === id ? null : id);
    };

    return (
        features.length > 0 ?
            <div className="product-features-main-container section-spacing">
                <div className="container">
                    <div className="container-fluid">
                        <div className="information-product-name">
                            <h5>
                                Features Included
                            </h5>
                        </div>

                        {
                            features.map((feature) => {
                                const isExpanded = expandedFeatureId === feature.id;
                                const displayDescription = isExpanded ? feature.description : truncateText(feature.description, 50);

                                return (
                                    <div className="row content-middle mb-4" key={feature.id}>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 product-feature-container">
                                            <div className="product-feature-heading">
                                                <h4>
                                                    {feature.heading}
                                                </h4>
                                            </div>

                                            <div className="product-feature-image-container">
                                                <img
                                                    alt={feature.alt}
                                                    src={getImageUrl(feature.image)}
                                                    className="img-fluid product-feature-image"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 product-feature-container">
                                            <div
                                                className="product-feature-description"
                                                dangerouslySetInnerHTML={{__html: displayDescription}}
                                            ></div>
                                            {feature.description.length > 300 && (
                                                <button onClick={() => toggleDescription(feature.id)}
                                                        className="feature-view-more">
                                                    {isExpanded ? 'Read Less' : 'Read More'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div> : null
    );

    function createResponse(response, isRelatedServices) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data : [];
            if (data) {
                if (isRelatedServices) {
                    const productFeatures = data.attributes ? data.attributes.Feature : [];
                    createFeatureResponse(productFeatures)
                } else {
                    data.forEach(product => {
                        const productFeatures = product.attributes ? product.attributes.Feature : [];
                        createFeatureResponse(productFeatures)
                    });
                }
            }
        }
    }

    function createFeatureResponse(productFeatures) {
        if (productFeatures) {
            productFeatures.forEach(feature => {
                let featureImage = feature.image ? feature.image.data.attributes : "";
                features.push({
                    id: feature.id,
                    heading: feature.heading,
                    description: feature.description,
                    image: featureImage.url,
                    alt: featureImage.alternativeText,
                });
            });
        }
    }

    function truncateText(text, wordLimit) {
        const words = text.split(' ');
        if (words.length <= wordLimit) return text;
        return words.slice(0, wordLimit).join(' ') + '...';
    }
}

Features.propTypes = {
    apiId: PropTypes.string.isRequired,
    slug: PropTypes.string,
    isRelatedServices: PropTypes.bool
};

export default Features;