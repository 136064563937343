import Contactus from "./Contactus";
import useFetch from "../../hooks/useFetch";
import {getImageUrl} from "../../services/util";
import SEOMetaTags from "../../components/SEOMetaTags";
import React from "react";

function ContactUsMain() {
    const apiId = "/contact-us";

    const url = process.env.REACT_APP_API_PATH + apiId + "?populate[0]=CallToActionButton&populate[1]=Image";
    const {response} = useFetch(url);

    let contactInformation = null;
    let callToActionButton = null;
    let image = null;
    createResponse(response);

    return (
        <section>
            <SEOMetaTags apiId={apiId} filters=""/>

            <div className="contact-us-main-container">
                <div className="container">
                    <div className="container-fluid section-spacing">
                        {
                            contactInformation ?
                                <div className="contact-us-header-container text-center">
                                    <div className="contact-us-heading-container">
                                        <h1>
                                            {contactInformation.heading}
                                        </h1>
                                    </div>

                                    <div className="contact-us-information-container">
                                        <div className="support-information">
                                            Call us at:
                                            <span>{contactInformation.support_contact_number}</span>
                                        </div>

                                        <div className="contact-us-icon"></div>

                                        <div className="support-information">
                                            Email us:
                                            <span>{contactInformation.support_email_address}</span>
                                        </div>
                                    </div>
                                </div> : null
                        }

                        <div className="contact-us-button-container text-center">
                            {
                                callToActionButton ?
                                    <a href={callToActionButton.slug}
                                       className={"btn btn-lg btn-block me-2 btn-" + callToActionButton.type}>{callToActionButton.name}</a> : null
                            }
                        </div>

                        <div className="contact-us-image-container">
                            {
                                image ?
                                    <img
                                        src={getImageUrl(image.image)}
                                        alt={image.alt} className="img-fluid"/> : null
                            }
                        </div>

                        <div id="home-contact-us-section" className="section-spacing position-relative">
                            <Contactus apiId={apiId}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    function createResponse(response) {
        if (response && response.data !== undefined) {
            const data = response.data ? response.data.attributes : [];
            if (data) {
                contactInformation = {
                    heading: data.MainHeading,
                    support_contact_number: data.supportContactNumber,
                    support_email_address: data.supportEmail,
                }

                if (data.CallToActionButton) {
                    callToActionButton = {
                        id: data.CallToActionButton.id,
                        name: data.CallToActionButton.title,
                        type: data.CallToActionButton.type,
                        slug: data.CallToActionButton.slug,
                    }
                }

                const contactUsImage = data.Image ? data.Image.data.attributes : [];
                if (contactUsImage) {
                    image = {
                        image: contactUsImage.url,
                        alt: contactUsImage.alternativeText,
                    }
                }
            }
        }
    }
}

export default ContactUsMain;