import React, { useMemo, useState, useEffect } from 'react';
import useFetch from "../../../hooks/useFetch";
import {getImageUrl} from "../../../services/util";
import PropTypes from "prop-types";

const Solutions = ({apiId}) => {
    const url = `${process.env.REACT_APP_API_PATH}${apiId}/?populate[0]=SolutionsHeading&populate[1]=Solutions&populate[2]=Solutions.icon&populate[3]=Solutions.category&populate[4]=Solutions.LinkButton`;
    const {response} = useFetch(url);

    // Use state variables for solutionsHeading and solutions
    const [solutionsHeading, setSolutionsHeading] = useState(null);
    const [solutions, setSolutions] = useState([]);

    // Memoize the processed data using useMemo
    const memoizedSolutions = useMemo(() => {
        return createResponse(response);
    }, [response]);

    // Update state with memoized data when response changes
    useEffect(() => {
        if (memoizedSolutions) {
            setSolutionsHeading(memoizedSolutions.solutionsHeading);
            setSolutions(memoizedSolutions.solutions);
        }
    }, [memoizedSolutions]);

    return (
        <div>
            <div className="solutions-heading-container">
                <h2>{solutionsHeading}</h2>
            </div>

            <div className="row">
                {solutions.map((item) => (
                    item.is_active ?
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-inline-grid" key={item.id}>
                            <div className="card solutions-main-container">
                                <a href={`/solutions/${item.slug}`} className="solutions-container">
                                    <div className="card-body">
                                        <div className="solutions-icon-section">
                                            {item.icon && (
                                                <img
                                                    loading="lazy"
                                                    src={getImageUrl(item.icon)}
                                                    alt={item.alt}
                                                    className="img-fluid icon-img-solutions"
                                                />
                                            )}
                                        </div>

                                        <div className="solutions-heading-section">
                                            <h4>{item.heading ?? ""}</h4>
                                        </div>

                                        <div className="solutions-description-section">
                                            {item.description}
                                        </div>
                                    </div>
                                </a>

                                <div className="card-footer">
                                    <a
                                        href={`/solutions/${item.slug}`}
                                        className="solutions-call-to-action"
                                        target={item.openNewWindow ? "_blank" : "_self"} // Open link in new tab if required
                                        rel={item.openNewWindow ? "noopener noreferrer" : ""} // Security best practice for new tabs
                                    >
                                        {item.buttonTitle}
                                    </a>
                                </div>
                            </div>
                        </div> : null
                ))}
            </div>
        </div>
    );
};

function createResponse(response) {
    const solutionsHeading = response?.data?.attributes?.SolutionsHeading || null;
    const solutionsData = response?.data?.attributes?.Solutions || [];

    const solutions = solutionsData.map((solution) => {
        const solutionIcon = solution.icon?.data?.attributes;
        const category = solution.category?.data?.attributes;
        const button = solution.LinkButton || {};

        return {
            id: solution.id,
            icon: solutionIcon ? solutionIcon.url : null,
            alt: solutionIcon ? solutionIcon.alternativeText : null,
            heading: category ? category.name : null,
            slug: category ? category.slug : null,
            is_active: category.is_active,
            description: solution.description,
            buttonTitle: button.title || "",
            openNewWindow: button.open_new_window || false,
        };
    });

    return {solutionsHeading, solutions};
}

Solutions.propTypes = {
    apiId: PropTypes.string.isRequired
};

export default Solutions;
